import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/about.vue')
  },
  {
    path: '/modalities',
    name: 'modalities',
    component: () => import('../views/modalities/modalities.vue'),
    children: [
      {
        path: '/modalities/',
        redirect: '/modalities/ov',
      },
      {
        path: '/modalities/ov',
        name: 'ov',
        component: () => import('../views/modalities/children/ov.vue'),
      },
      {
        path: '/modalities/mc',
        name: 'mc',
        component: () => import('../views/modalities/children/mc.vue')
      },
      {
        path: '/modalities/ic',
        name: 'ic',
        component: () => import('../views/modalities/children/ic.vue')
      },
      {
        path: '/modalities/vv',
        name: 'vv',
        component: () => import('../views/modalities/children/vv.vue')
      },
    ]
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/services/services.vue'),
    children: [
      {
        path: '/services/',
        redirect: '/services/dp',
      },
      {
        path: '/services/dp',
        name: 'dp',
        component: () => import('../views/services/children/dp.vue'),
      },
      {
        path: '/services/pd',
        name: 'pd',
        component: () => import('../views/services/children/pd.vue')
      },
      {
        path: '/services/pd_one',
        name: 'pd_one',
        component: () => import('../views/services/children/pd_one.vue')
      },
      {
        path: '/services/ad',
        name: 'ad',
        component: () => import('../views/services/children/ad.vue')
      },
      {
        path: '/services/cp',
        name: 'cp',
        component: () => import('../views/services/children/cp.vue')
      },
      {
        path: '/services/qc',
        name: 'qc',
        component: () => import('../views/services/children/qc.vue')
      },
    ]
  },
  {
    path: '/news_knowledge',
    name: 'news_knowledge',
    redirect: '/news',
    component: () => import('../views/news_knowledge/news_knowledge.vue'),
    children: [
      {
        path: '/news_knowledge/',
        redirect: '/news_knowledge/news',
      },
      {
        path: '/news_knowledge/news',
        name: 'news',
        component: () => import('../views/news_knowledge/children/news.vue')
      },
      {
        path: '/news_knowledge/news_detail',
        name: 'news_detail',
        component: () => import('../views/news_knowledge/children/news_detail.vue')
      },
      {
        path: '/news_knowledge/knowledge_center',
        name: 'knowledge_center',
        component: () => import('../views/news_knowledge/children/knowledge_center.vue')
      },
      {
        path: '/news_knowledge/knowledge_center_detail',
        name: 'knowledge_center_detail',
        component: () => import('../views/news_knowledge/children/knowledge_center_detail.vue')
      },
    ]
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/contacts.vue')
  },
]

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  $("body").removeClass("large-nav-active");
  $("body").removeClass("masked");
});


export default router
