import Vue from "vue";
import axios from "axios";
import { Message } from "element-ui";
import VueRouter from "vue-router";
import router from "../router";

Vue.use(VueRouter);

// axios.defaults.withCredentials=true ;
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

//多个baseUrl多写几个create
//同源策略
let request = axios.create({
  // baseURL: "http://www.yxgene.com:8899/",
  baseURL: '/',
  timeout: 30000,
});

request.defaults.headers.post["Content-Type"] = `application/json`;

//请求拦截器
request.interceptors.request.use(
  (config) => {
    let userinfo = window.sessionStorage.getItem("robotToken");
    if (userinfo) {
      let token = userinfo;
      // 注意：token前边有 'Bearer ' 的信息前缀,API接口需求，Bearer后边有空格
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    console.log(error, "error");
  }
);

//响应拦截器
request.interceptors.response.use(
  (res) => {
    if (res.data.code == 200) {
      return res.data;
    } else {
      return res.data;
    }
  },
  (error) => {
    if (error.response) {
      Message.error("服务器异常!");
    } else {
      // 发送请求时出了点问题
      console.log("请求异常！");
    }

    return null;
  }
);
//封装axios
export { request };
