// src/globals.js
export default {
  countries: [
    { countryZh: "Country" },
    { countryZh: "Afghanistan", areaCode: "+93" },
    { countryZh: "Albania", areaCode: "+355" },
    { countryZh: "Algeria", areaCode: "+213" },
    { countryZh: "Andorra", areaCode: "+376" },
    { countryZh: "Angola", areaCode: "+244" },
    { countryZh: "Argentina", areaCode: "+54" },
    { countryZh: "Armenia", areaCode: "+374" },
    { countryZh: "Australia", areaCode: "+61" },
    { countryZh: "Austria", areaCode: "+43" },
    { countryZh: "Azerbaijan", areaCode: "+994" },
    { countryZh: "Bahrain", areaCode: "+973" },
    { countryZh: "Bangladesh", areaCode: "+880" },
    { countryZh: "Belarus", areaCode: "+375" },
    { countryZh: "Belgium", areaCode: "+32" },
    { countryZh: "Belize", areaCode: "+501" },
    { countryZh: "Benin", areaCode: "+229" },
    { countryZh: "Bhutan", areaCode: "+975" },
    { countryZh: "Bolivia", areaCode: "+591" },
    { countryZh: "Bosnia_and_Herzegovina", areaCode: "+387" },
    { countryZh: "Botswana", areaCode: "+267" },
    { countryZh: "Brazil", areaCode: "+55" },
    { countryZh: "Brunei", areaCode: "+673" },
    { countryZh: "Bulgaria", areaCode: "+359" },
    { countryZh: "Burkina_Faso", areaCode: "+226" },
    { countryZh: "Burundi", areaCode: "+257" },
    { countryZh: "Cambodia", areaCode: "+855" },
    { countryZh: "Cameroon", areaCode: "+237" },
    { countryZh: "Canada", areaCode: "+1" },
    { countryZh: "Cape_Verde", areaCode: "+238" },
    { countryZh: "Central_African_Republic", areaCode: "+236" },
    { countryZh: "Chad", areaCode: "+235" },
    { countryZh: "Chile", areaCode: "+56" },
    { countryZh: "China", areaCode: "+86" },
    { countryZh: "Colombia", areaCode: "+57" },
    { countryZh: "Comoros", areaCode: "+269" },
    { countryZh: "Congo", areaCode: "+242" },
    { countryZh: "Costa_Rica", areaCode: "+506" },
    { countryZh: "Croatia", areaCode: "+385" },
    { countryZh: "Cuba", areaCode: "+53" },
    { countryZh: "Cyprus", areaCode: "+357" },
    { countryZh: "Czech_Republic", areaCode: "+420" },
    { countryZh: "Denmark", areaCode: "+45" },
    { countryZh: "Djibouti", areaCode: "+253" },
    { countryZh: "Dominica", areaCode: "+1-767" },
    { countryZh: "Dominican_Republic", areaCode: "+1-809" },
    { countryZh: "Ecuador", areaCode: "+593" },
    { countryZh: "Egypt", areaCode: "+20" },
    { countryZh: "El_Salvador", areaCode: "+503" },
    { countryZh: "Equatorial_Guinea", areaCode: "+240" },
    { countryZh: "Eritrea", areaCode: "+291" },
    { countryZh: "Estonia", areaCode: "+372" },
    { countryZh: "Eswatini", areaCode: "+268" },
    { countryZh: "Ethiopia", areaCode: "+251" },
    { countryZh: "Fiji", areaCode: "+679" },
    { countryZh: "Finland", areaCode: "+358" },
    { countryZh: "France", areaCode: "+33" },
    { countryZh: "Gabon", areaCode: "+241" },
    { countryZh: "Gambia", areaCode: "+220" },
    { countryZh: "Georgia", areaCode: "+995" },
    { countryZh: "Germany", areaCode: "+49" },
    { countryZh: "Ghana", areaCode: "+233" },
    { countryZh: "Greece", areaCode: "+30" },
    { countryZh: "Grenada", areaCode: "+1-473" },
    { countryZh: "Guatemala", areaCode: "+502" },
    { countryZh: "Guinea", areaCode: "+224" },
    { countryZh: "Guinea_Bissau", areaCode: "+245" },
    { countryZh: "Guyana", areaCode: "+592" },
    { countryZh: "Haiti", areaCode: "+509" },
    { countryZh: "Honduras", areaCode: "+504" },
    { countryZh: "Hungary", areaCode: "+36" },
    { countryZh: "Iceland", areaCode: "+354" },
    { countryZh: "India", areaCode: "+91" },
    { countryZh: "Indonesia", areaCode: "+62" },
    { countryZh: "Iran", areaCode: "+98" },
    { countryZh: "Iraq", areaCode: "+964" },
    { countryZh: "Ireland", areaCode: "+353" },
    { countryZh: "Israel", areaCode: "+972" },
    { countryZh: "Italy", areaCode: "+39" },
    { countryZh: "Ivory_Coast", areaCode: "+225" },
    { countryZh: "Jamaica", areaCode: "+1-876" },
    { countryZh: "Japan", areaCode: "+81" },
    { countryZh: "Jordan", areaCode: "+962" },
    { countryZh: "Kazakhstan", areaCode: "+7" },
    { countryZh: "Kenya", areaCode: "+254" },
    { countryZh: "Kiribati", areaCode: "+686" },
    { countryZh: "North_Korea", areaCode: "+850" },
    { countryZh: "South_Korea", areaCode: "+82" },
    { countryZh: "Kuwait", areaCode: "+965" },
    { countryZh: "Kyrgyzstan", areaCode: "+996" },
    { countryZh: "Laos", areaCode: "+856" },
    { countryZh: "Latvia", areaCode: "+371" },
    { countryZh: "Lebanon", areaCode: "+961" },
    { countryZh: "Lesotho", areaCode: "+266" },
    { countryZh: "Liberia", areaCode: "+231" },
    { countryZh: "Libya", areaCode: "+218" },
    { countryZh: "Liechtenstein", areaCode: "+423" },
    { countryZh: "Lithuania", areaCode: "+370" },
    { countryZh: "Luxembourg", areaCode: "+352" },
    { countryZh: "Madagascar", areaCode: "+261" },
    { countryZh: "Malawi", areaCode: "+265" },
    { countryZh: "Malaysia", areaCode: "+60" },
    { countryZh: "Maldives", areaCode: "+960" },
    { countryZh: "Mali", areaCode: "+223" },
    { countryZh: "Malta", areaCode: "+356" },
    { countryZh: "Marshall_Islands", areaCode: "+692" },
    { countryZh: "Mauritania", areaCode: "+222" },
    { countryZh: "Mauritius", areaCode: "+230" },
    { countryZh: "Mexico", areaCode: "+52" },
    { countryZh: "Micronesia", areaCode: "+691" },
    { countryZh: "Moldova", areaCode: "+373" },
    { countryZh: "Monaco", areaCode: "+377" },
    { countryZh: "Mongolia", areaCode: "+976" },
    { countryZh: "Montenegro", areaCode: "+382" },
    { countryZh: "Morocco", areaCode: "+212" },
    { countryZh: "Mozambique", areaCode: "+258" },
    { countryZh: "Myanmar", areaCode: "+95" },
    { countryZh: "Namibia", areaCode: "+264" },
    { countryZh: "Nauru", areaCode: "+674" },
    { countryZh: "Nepal", areaCode: "+977" },
    { countryZh: "Netherlands", areaCode: "+31" },
    { countryZh: "New_Zealand", areaCode: "+64" },
    { countryZh: "Nicaragua", areaCode: "+505" },
    { countryZh: "Niger", areaCode: "+227" },
    { countryZh: "Nigeria", areaCode: "+234" },
    { countryZh: "North_Macedonia", areaCode: "+389" },
    { countryZh: "Norway", areaCode: "+47" },
    { countryZh: "Oman", areaCode: "+968" },
    { countryZh: "Pakistan", areaCode: "+92" },
    { countryZh: "Palau", areaCode: "+680" },
    { countryZh: "Panama", areaCode: "+507" },
    { countryZh: "Papua_New_Guinea", areaCode: "+675" },
    { countryZh: "Paraguay", areaCode: "+595" },
    { countryZh: "Peru", areaCode: "+51" },
    { countryZh: "Philippines", areaCode: "+63" },
    { countryZh: "Poland", areaCode: "+48" },
    { countryZh: "Portugal", areaCode: "+351" },
    { countryZh: "Qatar", areaCode: "+974" },
    { countryZh: "Romania", areaCode: "+40" },
    { countryZh: "Russia", areaCode: "+7" },
    { countryZh: "Rwanda", areaCode: "+250" },
    { countryZh: "Saint_Kitts_and_Nevis", areaCode: "+1-869" },
    { countryZh: "Saint_Lucia", areaCode: "+1-758" },
    { countryZh: "Saint_Vincent_and_the_Grenadines", areaCode: "+1-784" },
    { countryZh: "Samoa", areaCode: "+685" },
    { countryZh: "San_Marino", areaCode: "+378" },
    { countryZh: "Sao_Tome_and_Principe", areaCode: "+239" },
    { countryZh: "Saudi_Arabia", areaCode: "+966" },
    { countryZh: "Senegal", areaCode: "+221" },
    { countryZh: "Serbia", areaCode: "+381" },
    { countryZh: "Seychelles", areaCode: "+248" },
    { countryZh: "Sierra_Leone", areaCode: "+232" },
    { countryZh: "Singapore", areaCode: "+65" },
    { countryZh: "Slovakia", areaCode: "+421" },
    { countryZh: "Slovenia", areaCode: "+386" },
    { countryZh: "Solomon_Islands", areaCode: "+677" },
    { countryZh: "Somalia", areaCode: "+252" },
    { countryZh: "South_Africa", areaCode: "+27" },
    { countryZh: "South_Sudan", areaCode: "+211" },
    { countryZh: "Spain", areaCode: "+34" },
    { countryZh: "Sri_Lanka", areaCode: "+94" },
    { countryZh: "Sudan", areaCode: "+249" },
    { countryZh: "Suriname", areaCode: "+597" },
    { countryZh: "Sweden", areaCode: "+46" },
    { countryZh: "Switzerland", areaCode: "+41" },
    { countryZh: "Syria", areaCode: "+963" },
    { countryZh: "Taiwan", areaCode: "+886" },
    { countryZh: "Tajikistan", areaCode: "+992" },
    { countryZh: "Tanzania", areaCode: "+255" },
    { countryZh: "Thailand", areaCode: "+66" },
    { countryZh: "Timor_Leste", areaCode: "+670" },
    { countryZh: "Togo", areaCode: "+228" },
    { countryZh: "Tonga", areaCode: "+676" },
    { countryZh: "Trinidad_and_Tobago", areaCode: "+1-868" },
    { countryZh: "Tunisia", areaCode: "+216" },
    { countryZh: "Turkey", areaCode: "+90" },
    { countryZh: "Turkmenistan", areaCode: "+993" },
    { countryZh: "Tuvalu", areaCode: "+688" },
    { countryZh: "Uganda", areaCode: "+256" },
    { countryZh: "Ukraine", areaCode: "+380" },
    { countryZh: "United_Arab_Emirates", areaCode: "+971" },
    { countryZh: "United_Kingdom", areaCode: "+44" },
    { countryZh: "United_States", areaCode: "+1" },
    { countryZh: "Uruguay", areaCode: "+598" },
    { countryZh: "Uzbekistan", areaCode: "+998" },
    { countryZh: "Vanuatu", areaCode: "+678" },
    { countryZh: "Vatican_City", areaCode: "+379" },
    { countryZh: "Venezuela", areaCode: "+58" },
    { countryZh: "Vietnam", areaCode: "+84" },
    { countryZh: "Yemen", areaCode: "+967" },
    { countryZh: "Zambia", areaCode: "+260" },
    { countryZh: "Zimbabwe", areaCode: "+263" },
  ],
};
