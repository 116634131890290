export default function () {
  /*
 * 对两段svg path进行morph动画
 * 使用相同的data-morph指定所在组
 * 分别在data-stop中使用from和to指定开始和结束路径
 */

  // document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(MorphSVGPlugin)

  const paths = document.querySelectorAll('*[data-morph][data-stop="from"]');
  Array.from(paths).forEach(from => {
    if (from.tagName !== 'PATH') {
      from = MorphSVGPlugin.convertToPath(from)[0];
    }

    let morphName = from.getAttribute('data-morph');
    let target = document.querySelector('*[data-morph="' + morphName + '"][data-stop="to"]');
    if (target.tagName !== 'PATH') {
      target = MorphSVGPlugin.convertToPath(target)[0];
    }

    let num = from.getAttribute('data-num');
    num = num ? num * 1 : 8;

    let duration = from.getAttribute('data-duration');
    duration = duration ? duration * 1 : 3;
    const durationSlice = duration / (num + 2);
    const morphDuration = durationSlice * num

    let ease = from.getAttribute('data-ease') || 'none';

    const startStyles = getComputedStyle(from);
    const endStyles = getComputedStyle(target);

    const startColor = startStyles.getPropertyValue('stroke');
    const endColor = endStyles.getPropertyValue('stroke');

    const startWidth = startStyles.getPropertyValue('stroke-width');
    const endWidth = endStyles.getPropertyValue('stroke-width');

    const startOpacity = startStyles.getPropertyValue('opacity');
    const endOpacity = endStyles.getPropertyValue('opacity');

    const startPath = from.getAttribute('d');
    const endPath = target.getAttribute('d');

    for (let i = 0; i < num; i++) {
      const startPathCopy = from.cloneNode(true);
      startPathCopy.classList.remove('proto');
      from.parentElement.insertBefore(startPathCopy, from);

      const timeline = gsap.timeline({ repeat: -1, delay: -duration + duration / num * i });

      timeline.fromTo(startPathCopy, {
        opacity: 0
      },
        {
          opacity: startOpacity,
          duration: durationSlice,
          onStart() {
            startPathCopy.setAttribute('d', startPath);
          }
        },
      )

      timeline.fromTo(startPathCopy, {
        morphSVG: {
          shape: startPath,
        },
        stroke: startColor,
        strokeWidth: startWidth,
        opacity: startOpacity,
      }, {
        morphSVG: {
          shape: endPath,
          //type: "rotational"
        },
        stroke: endColor,
        strokeWidth: endWidth,
        opacity: endOpacity,
        ease: ease,
        duration: morphDuration,
        autoRound: false,
        modifiers: {
          strokeWidth: function (i) {
            return i;
          }
        }
      });

      timeline.fromTo(startPathCopy, {
        opacity: endOpacity
      },
        {
          opacity: 0,
          duration: durationSlice
        },
      )
    }

    target.style.opacity = '0'
    from.style.opacity = '0'
  })
  // })

}