export default function () {
  // $('.btn-large-nav-expand').click(function () {
  //   let $body = $('body');
  //   if ($body.hasClass('large-nav-active')) {
  //     if (window.innerWidth <= 750) {
  //       if ($('.large-nav>.right').hasClass('active')) {
  //         $('.large-nav>.right').removeClass('active')
  //       } else {
  //         $body.removeClass('large-nav-active');
  //       }
  //     } else {
  //       $body.removeClass('large-nav-active');
  //     }
  //     $body.removeClass('masked');
  //     $('.large-nav>.right').removeClass('active');
  //   } else {
  //     $body.addClass('large-nav-active');
  //     $body.addClass('masked');
  //   }
  // })

  // let $title = $('.large-nav-title');
  // let $content = $('.large-nav-content');

  // $title.click(function (e) {
  //   if (window.innerWidth <= 750) {
  //     location.href = $(this).attr('data-url');
  //   }
  // })

  // $('.large-nav-tab').mouseenter(function () {
  //   let $tab = $(this);
  //   $tab.addClass('active').siblings().removeClass('active');
  //   let title = $tab.text().trim();
  //   let href = $tab.attr('href');
  //   let index = $tab.attr('data-refer');

  //   $content.empty();
  //   let $sub = $('.top-nav-sub[data-index="' + index + '"]');
  //   if ($sub.length) {
  //     $title.text(title);
  //     $title.attr('data-url', href);
  //     $sub.clone().removeClass('top-nav-sub').appendTo($content);
  //   } else {
  //     $title.text('');
  //     $title.attr('data-url', '');
  //   }
  // }).eq(0).trigger('mouseenter');

  // $('.large-nav-tab').mouseenter(function (e) {
  //   if (window.innerWidth <= 750) {
  //     $('.large-nav>.right').addClass('active');
  //   }
  // })

  // $('.large-nav-tab').click(function (e) {
  //   if (window.innerWidth <= 750) {
  //     e.preventDefault();
  //   }
  // })

  // $('.lang-switch').hover(function () {
  //   $(this).toggleClass('active')
  // })

  // $('.btn-search-top .open-search').click(function (e) {
  //   e.stopPropagation()
  //   $(this).parent().toggleClass('active')
  // })

  // $('.btn-search-top .close-btn').click(function (e) {
  //   e.stopPropagation()
  //   $(this).parents('.btn-search-top').removeClass('active')
  // })

  // $('.openDialog').click(function () {
  //   $('body').addClass('masked')
  //   $('.dialog').addClass('active')
  //   // $('.dialog-wrap img').on('load', function () {
  //   // })
  //   $('.dialog-close').css('right', $('.dialog-wrap').width() + 'px')
  // })

  // $('.dialog-close').click(function (e) {
  //   e.stopPropagation()
  //   $('body').removeClass('masked')
  //   $('.dialog').removeClass('active')
  // })

  // $('.dialog-bg').click(function (e) {
  //   e.stopPropagation()
  //   $('body').removeClass('masked')
  //   $('.dialog').removeClass('active')
  // })

  // $(window).on('scroll', function () {
  //   smoother()
  // })

  // smoother()

  // function smoother() {
  //   if ($(window).scrollTop() <= $('.site-header-bar').height()) {
  //     $('body').removeClass('scrolled');
  //   } else {
  //     $('body').addClass('scrolled');
  //   }
  //   var anchor = $('.anchor-nav')
  //   if (anchor.length) {
  //     if ($('.anchor-nav').offset().top - $(window).scrollTop() <= $('.site-header-bar').height()) {
  //       $('.site-header').fadeOut()
  //       $('.site-logo').fadeOut()
  //       $('.btn-large-nav-expand').fadeOut()
  //     } else {
  //       $('.site-header').fadeIn()
  //       $('.site-logo').fadeIn()
  //       $('.btn-large-nav-expand').fadeIn()
  //     }
  //   }
  // }

  // //回到顶部
  // var backToTop = document.getElementById('backToTop')
  // console.log(backToTop);
  // if (backToTop) {
  //   backToTop.addEventListener('click', function () {
  //     console.log(2221);
  //     scrollToTop(500); // 设置动画时间，单位为毫秒
  //   });

  //   function scrollToTop(scrollDuration) {
  //     var scrollStep = -window.scrollY / (scrollDuration / 15),
  //       scrollInterval = setInterval(function () {
  //         if (window.scrollY !== 0) {
  //           window.scrollBy(0, scrollStep);
  //         } else {
  //           clearInterval(scrollInterval);
  //         }
  //       }, 15);
  //   }
  // }

  // 将加载了svg图像的img转换为svg元素
  $('img').toArray().forEach(item => {
    if ($(item).attr('data-type') == 'notchange' || item.src.substr(item.src.length - 3, 3) != "svg") return false
    imgtosvg(item)
  })

  function imgtosvg(img) {
    var r = new XMLHttpRequest()
    r.onload = e => {
      var t = document.createElement("div")
      t.innerHTML = r.responseXML.documentElement.outerHTML
      $(t.firstElementChild).addClass($(img).attr('class'))
      img.parentElement && img.parentElement.insertBefore(t.firstElementChild, img)
      img.remove()
    }
    r.open("get", img.src)
    r.send()
  }

  function endoscopy(wi, el) {
    const complete = el.find('.complete')[0];
    const circle = el.find('.ring')[0];
    var elementLeft, elementWidth, elementHeight, elementTop, getData = true;
    complete.style.cursor = 'none';
    circle.style.cursor = 'none';
    function moveCircle(event) {
      event.stopPropagation()
      var elementRect = complete.getBoundingClientRect();
      if (getData) {
        getData = false
        elementLeft = elementRect.left;
        elementTop = elementRect.top;
        elementWidth = elementRect.width;
        elementHeight = elementRect.height;
      }

      var mouseX = event.clientX;
      var mouseY = event.clientY;
      var distanceLeft = mouseX - elementLeft;
      var distanceTop = mouseY - elementTop;
      if (distanceLeft > 0 && distanceLeft < elementWidth || distanceTop > 0 && distanceTop < elementHeight) {
        circle.style.display = 'block'
        circle.style.clipPath = 'circle(' + wi + 'px at ' + distanceLeft + 'px ' + distanceTop + 'px)'
      }
    }
    complete.addEventListener('mousemove', moveCircle, true);
    circle.addEventListener('mousemove', moveCircle, true);
    // $(window).on('resize', function () {
    //   document.querySelector('.complete').removeEventListener('mousemove', moveCircle, true);
    //   document.querySelector('.ring').removeEventListener('mousemove', moveCircle, true);
    //   endoscopy(120 / 1920 * window.innerWidth, $('.footer-intro'))
    //   endoscopy(240 / 1920 * window.innerWidth, $('.banner'))
    // })
  }
  //显微镜效果， 第一个参数是圆圈的半径， 
  // endoscopy(120 / 1920 * window.innerWidth, $('.footer-intro'))
  // $('.banner .endoscopy').each((index, item) => {
  //   endoscopy(240 / 1920 * window.innerWidth, $(item))
  // })

  function setDna(el, ringPosition) {
    var container = el
    var scene = new THREE.Scene();


    var width = container.innerWidth();
    var height = container.innerHeight();
    var aspect = width / height;
    var halfViewSize = 32; // 根据透视相机的fov值来估算一个大致相等的视野大小

    var camera = new THREE.PerspectiveCamera(10, width / height, 0.1, 1000);

    camera.position.z = 360;
    if (!Detector.webgl) Detector.addGetWebGLMessage();
    var renderer = Detector.webgl ? new THREE.WebGLRenderer({ antialias: true }) : new THREE.CanvasRenderer();

    var cylinderColor = 0x77CDE2;
    var purple = 'rgb(32,105,220)';

    renderer.setSize(width, height);

    container.append(renderer.domElement);

    var tubeGeometry = new THREE.CylinderGeometry(0.05, 0.05, 10.8, 32);
    var ballGeometry = new THREE.SphereGeometry(.5, 32, 32);

    var cylinderMaterial = new THREE.MeshBasicMaterial({ color: cylinderColor });
    var purpleMaterial = new THREE.MeshBasicMaterial({ color: purple });

    var dna = new THREE.Object3D();
    var holder = new THREE.Object3D();

    var totalRows = ringPosition.totalRows;
    var rowGap = 1.6;

    function gradientColor(startColor, endColor, steps) {
      // 提取颜色值的RGB部分
      var startRGB = startColor.substring(1).match(/.{2}/g)
        .map(function (hex) { return parseInt(hex, 16) });
      var endRGB = endColor.substring(1).match(/.{2}/g)
        .map(function (hex) { return parseInt(hex, 16) });

      // 确保颜色值的个数正确
      if (startRGB.length !== 3 || endRGB.length !== 3) {
        throw new Error('颜色值应该有3个数字，分别表示RGB的分量');
      }

      // 确保步数是正数
      if (steps <= 0) {
        throw new Error('步数应该是一个正数');
      }

      var stepsRGB = [];

      for (var i = 0; i <= steps; i++) {
        var step = i / steps;
        var r = Math.floor(startRGB[0] * (1 - step) + endRGB[0] * step);
        var g = Math.floor(startRGB[1] * (1 - step) + endRGB[1] * step);
        var b = Math.floor(startRGB[2] * (1 - step) + endRGB[2] * step);
        stepsRGB.push('rgb(' + r + ',' + g + ',' + b + ')');
      }

      return stepsRGB;
    }

    var gradientColors
    // 渐变色
    gradientColors = gradientColor('#58E9FE', '#2574DF', totalRows);

    function setCylinderMaterial(i) {
      return new THREE.MeshBasicMaterial({ color: gradientColors[i] });
    }
    for (var i = 0; i < totalRows; i++) {
      // var cylinder = new THREE.Mesh(tubeGeometry, cylinderMaterial);
      var cylinder = new THREE.Mesh(tubeGeometry, setCylinderMaterial(i));
      cylinder.rotation.z = 90 * Math.PI / 180;
      cylinder.position.x = 0;

      // var ballRight = new THREE.Mesh(ballGeometry, purpleMaterial);
      var ballRight = new THREE.Mesh(ballGeometry, setCylinderMaterial(i));
      ballRight.position.x = 5;

      // var ballLeft = new THREE.Mesh(ballGeometry, purpleMaterial);
      var ballLeft = new THREE.Mesh(ballGeometry, setCylinderMaterial(i));
      ballLeft.position.x = -5;

      var row = new THREE.Object3D();

      row.add(cylinder);
      row.add(ballRight);
      row.add(ballLeft);

      row.position.set(ringPosition.x, (i - totalRows / 2) * rowGap + ringPosition.y, ringPosition.z);

      row.rotation.y = i * 0.36;

      dna.add(row);
    }

    dna.rotation.z = Math.PI * .72;
    scene.add(dna);

    // var takeScreenshotCounter = 2;
    var render = function () {
      //holder.rotation.x += 0.00;
      //dna.rotation.z += 0.002;
      updateDnaRow();
      requestAnimationFrame(render);

      renderer.render(scene, camera);

      // if (--takeScreenshotCounter <= 0) {
      //   console.log(1);
      //   // 生成canvas图片
      //   var canvas = renderer.domElement;
      //   var dataURL = canvas.toDataURL('image/png');

      //   // 创建a标签并插入页面
      //   var a = document.createElement('a');
      //   a.href = dataURL;
      //   a.download = 'screenshot_' + new Date().getTime() + '.webp'; // 设置下载的文件名
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);

      //   // 重置计数器，每隔2帧再生成一次
      //   takeScreenshotCounter = 2;
      // }

    }

    function updateDnaRow() {
      dna.children.forEach(function (row, index) {
        // 每一行的旋转角度增加一定量
        row.rotation.y += 0.008;
      });
    }

    render();

    // 监听窗口大小变化事件
    window.addEventListener('resize', function () {
      var container = el
      var width = container.innerWidth();
      var height = container.innerHeight();
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    }, false);
  }

  // dna效果
  // $('.banner .endoscopy .ring').each((index, item) => {
  //   setDna($(item), { x: -27, y: -20, z: 0, totalRows: 120 })
  // })

  // setDna($('.footer-cont .endoscopy .ring'), { x: -15, y: 0, z: 0, totalRows: 80 })

  try {
    var wow
    if(!wow){
      wow = new WOW(
        {
          animateClass: 'animate__animated',
          offset: 100,
        }
      );
      wow.init();
    }
  } catch { }


  // var link = document.querySelectorAll('.custom-nav a');
  // // 监听点击事件
  // link.forEach(element => {
  //   element.addEventListener('click', function (event) {
  //     // 阻止默认的跳转行为
  //     event.preventDefault();
  //     // 清空当前路由的逻辑
  //     // 例如，使用history.replaceState来清空历史记录
  //     history.replaceState(null, document.title, '/');
  //     // 手动跳转到目标页面（如果需要的话）
  //     window.location.href = this.href;
  //   });
  // });


  // let scroll = function (el) {
  //   const offset = 80; // 距离页面顶部的偏移量

  //   const rect = el.getBoundingClientRect();
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //   const targetScrollTop = rect.top + scrollTop - offset;

  //   window.scrollTo({
  //     top: targetScrollTop,
  //     behavior: 'smooth'
  //   });
  // }

  // $(document).ready(function () {
  //   // 监听路由变化事件
  //   $(window).on('hashchange', function () {
  //     hashScroll()
  //   })
  // })

  // function hashScroll() {
  //   if (window.location.hash) {
  //     var anchor = Number(window.location.hash.split('#')[1]) - 1
  //     if(anchor){
  //       scroll($('.target-scroll').eq(anchor)[0])
  //     }
  //   }
  // }

  // hashScroll()
}
