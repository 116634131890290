import { request } from "./request";
import qs from "qs";

//发展历程(已完成)
export function developHistory() {
  return request({
    method: "get",
    url: "api/developHistory/fetch/1",
  });
}

//查询雇员(已完成)
export function employees() {
  return request({
    method: "get",
    url: "api/employees/fetch/1",
  });
}

//新闻分页(已完成)
export function fetch(data) {
  return request({
    method: "get",
    url: "api/news/fetch/1",
    params: data,
  });
}

//最新新闻(已完成)
export function fetchLatest() {
  return request({
    method: "get",
    url: "api/news/fetchLatest/1",
  });
}

//随机推荐新闻(已完成)
export function fetchReadAlsoNews(id) {
  return request({
    method: "get",
    url: "api/news/fetchReadAlsoNews/1?curNewId=" + id,
  });
}

//知识中心类型 不包含all(完成)
export function knowledgeTypes() {
  return request({
    method: "get",
    url: "api/knowledgeCenter/getKnowledgeTypes/1",
  });
}

//最新知识(完成)
export function knowledgeLatest() {
  return request({
    method: "get",
    url: "api/knowledgeCenter/fetchLatest/1",
  });
}

//知识分页(完成)
export function knowledgeFetch(data) {
  return request({
    method: "get",
    url: "api/knowledgeCenter/fetch/1",
    params: data,
  });
}

//随机推荐知识(已完成)
export function fetchReadAlsoKnowledge(id) {
  return request({
    method: "get",
    url: "api/knowledgeCenter/fetchReadAlsoKnowledge/1?curKnowledgeId=" + id,
  });
}

//查询岗位(已完成)
export function jobPosition() {
  return request({
    method: "get",
    url: "api/jobPosition/fetch/1",
  });
}

//联系我们(完成)
export function contactUs(data) {
  return request({
    method: "post",
    url: "api/contactUs/submit/1",
    data: data,
  });
}

//查询首页(已完成)
export function homepageInfo() {
  return request({
    method: "get",
    url: "api/homepageInfo/get/1",
  });
}
