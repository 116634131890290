<template>
  <div class="site-footer">
    <div class="footer-cont">
      <div class="footer-nav">
        <div class="item">
          <div class="title">
            <router-link :to="{ path: '/about' }">About</router-link>
          </div>
          <div class="link-wrap">
            <router-link
              :to="{ path: '/about', query: { target: 0 } }"
              class="link"
              >Our Company
            </router-link>
            <router-link
              :to="{ path: '/about', query: { target: 1 } }"
              class="link"
              >Our DNA
            </router-link>
            <router-link
              :to="{ path: '/about', query: { target: 2 } }"
              class="link"
              >Our Facility
            </router-link>
            <router-link
              :to="{ path: '/about', query: { target: 3 } }"
              class="link"
              >Our History</router-link
            >
          </div>
        </div>
        <div class="item">
          <div class="title">
            <router-link to="/modalities">Modalities</router-link>
          </div>
          <div class="link-wrap">
            <router-link
              :to="{ path: '/modalities/ov', query: { id: 0 } }"
              class="link"
              >Oncolytic Virus</router-link
            >
            <router-link
              :to="{ path: '/modalities/mc', query: { id: 1 } }"
              class="link"
              >mRNA</router-link
            >
            <router-link
              :to="{ path: '/modalities/ic', query: { id: 2 } }"
              class="link"
              >iPSC</router-link
            >
            <router-link
              :to="{ path: '/modalities/vv', query: { id: 3 } }"
              class="link"
              >Viral Vector</router-link
            >
          </div>
        </div>
        <div class="item">
          <div class="title">
            <router-link to="/services">Services</router-link>
          </div>
          <div class="link-wrap">
            <router-link to="/services/dp" class="link"
              >Research & Preclinical</router-link
            >
            <router-link to="/services/pd" class="link"
              >Process Development
            </router-link>
            <router-link to="/services/ad" class="link"
              >Analytical Development
            </router-link>
            <router-link to="/services/cp" class="link"
              >cGMP Production
            </router-link>
            <router-link to="/services/qc" class="link"
              >Quality & Compliance
            </router-link>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <router-link to="/news_knowledge"
              >News & Knowledge Center</router-link
            >
          </div>
          <div class="link-wrap">
            <router-link to="/news_knowledge/news" class="link"
              >News
            </router-link>
            <router-link to="/news_knowledge/knowledge_center" class="link"
              >Knowledge Center</router-link
            >
          </div>
        </div>
        <div class="item">
          <div class="title">
            <a>Shortcuts</a>
          </div>
          <div class="link-wrap">
            <router-link to="/contacts" class="link">Contacts </router-link>
            <router-link to="/join" class="link">Join </router-link>
            <a href="" class="link">Legal Notices </a>
            <a href="" class="link">Privacy Policy </a>
          </div>
        </div>
      </div>
      <div class="footer-intro">
        <div class="left">
          <div class="way-cont">
            <div class="title">Address & Contact</div>
            <div class="way-type">
              <p class="tel">
                <i class="icon"></i>
                <span>0755-26031097</span>
              </p>
              <p>
                <i class="icon"></i>
                <span
                  >3F, Building 2, Yinxing Zhijie Phase III, Xinlan Community,
                  Guanlan Street, Longhua District, Shenzhen
                </span>
              </p>
              <p>
                <i class="icon"></i>
                <span>BD@yxgene.com</span>
              </p>
            </div>
          </div>
          <div class="copyright">
            © 2024 Shenzhen Yuanxing Gene-tech Co.,LTD. Powered by
            <a href="https://www.webfoss.com/" target="_">WEBFOSS</a>.
            <a href="https://beian.miit.gov.cn" target="_">粤ICP备15109126号</a>
          </div>
        </div>
        <div class="right">
          <div class="link-list">
            <div class="item-cont">
              <a class="item flex-center">
                <img src="../assets/images/wechat.svg" alt="" class="icon" />
              </a>
              <div class="hover-qr">
                <img src="../assets/images/qr-wechat.png" alt="" />
              </div>
            </div>
            <a
              href="https://www.linkedin.com/company/29435439/admin/dashboard/"
              target="_"
              class="item flex-center"
            >
              <img src="../assets/images/Vector.svg" alt="" class="icon" />
            </a>
            <a href="" class="item flex-center">
              <img src="../assets/images/x.svg" alt="" class="icon" />
            </a>
            <div class="bg"></div>
          </div>
          <div class="endoscopy-cont">
            <div class="endoscopy">
              <div class="complete"></div>
              <div class="ring"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setDna from "../assets/js/setDna.js";
import endoscopy from "../assets/js/endoscopy.js";

export default {
  mounted() {
    // dna效果
    setDna($(".footer-cont .endoscopy .ring"), {
      x: -15,
      y: 0,
      z: 0,
      totalRows: 80,
    });
    endoscopy((120 / 1920) * window.innerWidth, $(".footer-intro"));
  },
};
</script>